<div class="details_body">
  <div class="details-card-host">
    <div class="card-header">
      <div class="fill"></div>
      <h2>We develop software for the following platforms:</h2>
      <div class="fill"></div>
      <!-- <button mat-fab  class="cancel-image" (click)="back()"></button> -->
    </div>
    <div class="details_container">
      <mat-card class="item details-card">
        <mat-card-header>
          <div mat-card-avatar class="android-icon"></div>
          <mat-card-title>Android</mat-card-title>
          <mat-card-subtitle>Kotlin, Java</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <ul>
            <li>
              IDE:
              <ul>
                <li>Android Studio</li>
              </ul>
            </li>
            <li>
              Architecture:
              <ul>
                <li>MVVM</li>
                <li>MVI</li>
                <li>Clean Architecture</li>
              </ul>
            </li>
            <li>
              Web integration:
              <ul>
                <li>REST</li>
                <li>Retrofit</li>
              </ul>
            </li>
            <li>
              Cloud integration:
              <ul>
                <li>Firebase / Firestore</li>
                <li>AWS</li>
              </ul>
            </li>
            <li>
              Databases:
              <ul>
                <li>SqLite</li>
                <li>Room</li>
                <li>Realm</li>
              </ul>
            </li>
            <li>
              Reactive:
              <ul>
                <li>RxJava</li>
                <li>Flow</li>
                <li>LiveData</li>
              </ul>
            </li>
            <li>
              Toolkits:
              <ul>
                <li>Android UI Toolkit</li>
              </ul>
              <ul>
                <li>Jetpack Compose</li>
              </ul>
            </li>
            <li>Material Design</li>
          </ul>
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <div mat-card-avatar class="ios-icon"></div>
          <mat-card-title>iOS</mat-card-title>
          <mat-card-subtitle>Swift, SwiftUI</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <ul>
            <li>
              IDE:
              <ul>
                <li>xCode</li>
              </ul>
            </li>
            <li>
              Architecture:
              <ul>
                <li>MVVM</li>
                <li>VIPER</li>
                <li>Clean Architecture</li>
              </ul>
            </li>
            <li>
              Web integration:
              <ul>
                <li>REST</li>
              </ul>
            </li>
            <li>
              Cloud integration:
              <ul>
                <li>Firebase / Firestore</li>
                <li>AWS</li>
              </ul>
            </li>
            <li>
              Databases:
              <ul>
                <li>CoreDB</li>
                <li>Realm</li>
                <li>SQLite</li>
              </ul>
            </li>
            <li>
              Reactive:
              <ul>
                <li>RxJava</li>
                <li>Combine</li>
              </ul>
            </li>
            <li>
              Toolkits:
              <ul>
                <li>UIKit</li>
              </ul>
              <ul>
                <li>SwiftUI</li>
              </ul>
            </li>
            <li>Material Design</li>
          </ul>
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <div mat-card-avatar class="flutter-icon"></div>
          <mat-card-title>Cross Platform</mat-card-title>
          <mat-card-subtitle>Dart</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <ul>
            <li>
              IDE:
              <ul>
                <li>Visual Studio Code</li>
              </ul>
            </li>
            <li>
              Frameworks
              <ul>
                <li>Flutter</li>
              </ul>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
