import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Location, ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-databases-details',
  templateUrl: './databases-details.component.html',
  styleUrls: ['./databases-details.component.scss']
})
export class DatabasesDetailsComponent implements AfterViewInit {

  constructor(private location: Location) { }

  @ViewChild("removeme", { read: ElementRef })
  tref!: ElementRef;

  ngAfterViewInit() {
    setTimeout(() => 
    {
      this.tref.nativeElement.remove();
    },
    2000);
  }

  back() {
    this.location.back();
  }
}
