<div class="item_body">
  <div class="flex-container">
    <div class="flex-item">
      <a href="{{ itemHRef }}">
        <mat-card>
          <mat-card-header>
            <mat-card-subtitle>{{ itemHeader }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <img src="{{ imgSrc }}" alt="{{ imgAlt }}" />
          </mat-card-content>
        </mat-card>
      </a>
    </div>
  </div>
</div>
