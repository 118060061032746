<div class="navbar">
  <mat-toolbar
  [ngClass]="{
    'mat-toolbar-home': isHomePage,
    'mat-toolbar': !isHomePage
  }">
    <mat-toolbar-row class="toolbar">
      <button mat-icon-button (click)="back();" fxShow="{{isShowBackButton}}" fxHide.gt-sm>
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span>Nortohol Inc {{pageHeader}}</span>
      <span class="spacer"></span>
      <div fxShow="true" fxHide.lt-md="true">
        <a href="#" routerLink="/" (click)="setBackButton(false)" mat-button>
          Home
        </a>
        <a href="#" routerLink="/services" (click)="setBackButton(false)" mat-button>
          Services
        </a>
        <a href="#" routerLink="/clients" (click)="setBackButton(false)" mat-button>
          Clients
        </a>
        <a href="#" routerLink="/about" (click)="setBackButton(false)" mat-button>
          Our Team
        </a>
        <a href="#" routerLink="/contact" (click)="setBackButton(false)" mat-button>
          Contact Us
        </a>
        <a href="#" routerLink="/credits" (click)="setBackButton(false)" mat-button>
          Credits
        </a>
      </div>
      <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav position="end" class="side_navbar">
      <mat-nav-list>
        <a href="#" routerLink="/" (click)="setBackButton(false);sidenav.close()" mat-list-item>
          Home
        </a>
        <mat-divider></mat-divider>
        <a href="#" routerLink="/services" (click)="setBackButton(false);sidenav.close()" mat-list-item>
          Services
        </a>
        <!-- <a href="#" (click)="sidenav.close();onSubmit('/services')" mat-list-item>
          Services
        </a> -->
        <mat-divider></mat-divider>
        <a href="#" routerLink="/clients" (click)="setBackButton(false);sidenav.close()" mat-list-item>
          <!-- <mat-icon>account_box</mat-icon> -->
          Clients
        </a>
        <mat-divider></mat-divider>
        <a href="#" routerLink="/about" (click)="setBackButton(false);sidenav.close()" mat-list-item>
          <!-- <mat-icon>account_box</mat-icon> -->
          Our Team
        </a>
        <mat-divider></mat-divider>
        <a href="#" routerLink="/contact" (click)="setBackButton(false);sidenav.close()" mat-list-item>
          <!-- <mat-icon>account_box</mat-icon> -->
          Contact Us
        </a>
        <mat-divider></mat-divider>
        <a href="#" routerLink="/credits" (click)="setBackButton(false);sidenav.close()" mat-list-item>
          <!-- <mat-icon>account_box</mat-icon> -->
          Credits
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>

      <div #contentArea class="container" [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<!-- <footer>
  Copyright © 2007-2021 Nortohol Inc.™
</footer> -->