<div class="details_body">
    <h1>Android</h1>
    <dl>
        <dt>IDE:</dt>
        <dd>- Android Studio</dd>

        <dt>Languages:</dt>
        <dd>- Java</dd>
        <dd>- Kotlin</dd>

        <dt>Architecture:</dt>
        <dd>- MVVM</dd>
        <dd>- MVI</dd>
        <dd>- Clean Architecture</dd>

        <dt>Cloud integration:</dt>
        <dd>- Firebase / Firestore</dd>
        <dd>- AWS</dd>

        <dt>R&D:</dt>
        <dd>- Jetpack Compose</dd>

        <dt>Material Design</dt>
    </dl>

    <h1>iOS</h1>
    <dl>
        <dt>IDE:</dt>
        <dd>- xCode</dd>

        <dt>Languages:</dt>
        <dd>- Swift</dd>

        <dt>Architecture:</dt>
        <dd>- MVVM</dd>
        <dd>- VIPER</dd>
        <dd>- Clean Architecture</dd>

        <dt>Cloud integration:</dt>
        <dd>- Firebase / Firestore</dd>
        <dd>- AWS</dd>

        <dt>R&D:</dt>
        <dd>- SwiftUI</dd>

        <dt>Material Design</dt>
    </dl>

</div>
