import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Location, ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-web-details',
  templateUrl: './web-details.component.html',
  styleUrls: ['./web-details.component.scss']
})
export class WebDetailsComponent implements AfterViewInit {

  constructor(private location: Location) { }

  // @ViewChild("removeme", { read: ElementRef })
  // tref!: ElementRef;

  ngAfterViewInit() {
    // setTimeout(() => 
    // {
    //   this.tref.nativeElement.remove();
    // },
    // 2000);
  }

  back() {
    this.location.back();
  }
}
