<div class="clients_body">
  <h1>Great people we have worked with:</h1>
  <h1>Staffing Agencies</h1>

  <div class="clients_container">
    <app-clients-item
      class="item"
      itemHeader="Ajilon Consulting"
      itemHRef="https://www.ajilon.com/"
      imgSrc="./assets/clients/ajilon.png"
      imgAlt="Ajilon Consulting"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Buchanan Associates"
      itemHRef="https://www.buchanan.com/"
      imgSrc="./assets/clients/buchanan.png"
      imgAlt="Buchanan Associates"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Kovasys IT Recruitment"
      itemHRef="https://kovasys.com/"
      imgSrc="./assets/clients/kovasys.png"
      imgAlt="Kovasys IT Recruitment"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Eagle Professional Resources Inc."
      itemHRef="https://www.eagleonline.com/"
      imgSrc="./assets/clients/eagle.png"
      imgAlt="Eagle Professional Resources Inc."
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Soroc Technology Inc."
      itemHRef="https://www.soroc.com/"
      imgSrc="./assets/clients/soroc.png"
      imgAlt="Soroc Technology Inc."
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="3 Degrees Creative Resourcing Inc."
      itemHRef="https://3deginc.com/"
      imgSrc="./assets/clients/de3rees.png"
      imgAlt="3 Degrees Creative Resourcing Inc."
    >
    </app-clients-item>
  </div>
  <h1>Direct Clients</h1>

  <div class="clients_container">
    <app-clients-item
      class="item"
      itemHeader="Bell Canada Inc."
      itemHRef="https://www.bell.ca"
      imgSrc="./assets/clients/bell.png"
      imgAlt="Bell Canada Inc."
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Maritz Canada Inc."
      itemHRef="https://www.bondbrandloyalty.com/solutions/"
      imgSrc="./assets/clients/maritz.png"
      imgAlt="Maritz Canada Inc."
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="MyAbilities"
      itemHRef="https://myabilities.com/"
      imgSrc="./assets/clients/myabilities.png"
      imgAlt="MyAbilities"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Unity Managing Underwriters Ltd."
      itemHRef="http://www.umu.net/"
      imgSrc="./assets/clients/unity.png"
      imgAlt="Unity Managing Underwriters Ltd."
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Postmedia Network Inc."
      itemHRef="https://www.postmedia.com/"
      imgSrc="./assets/clients/postmedia.png"
      imgAlt="Postmedia Network Inc."
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Davis + Henderson Corporation"
      itemHRef="https://www.dh-businessessentials.com/"
      imgSrc="./assets/clients/DH.png"
      imgAlt="Davis + Henderson Corporation"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Blast Radius"
      itemHRef="https://ebosscanada.com/directory/blast-radius/"
      imgSrc="./assets/clients/blast.png"
      imgAlt="Blast Radius"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Momentum Digital Solutions"
      itemHRef="https://www.onx.com/"
      imgSrc="./assets/clients/momentum.png"
      imgAlt="Momentum Digital Solutions"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Aquaterra"
      itemHRef="https://www.aquaterracorp.ca/"
      imgSrc="./assets/clients/aquaterra.png"
      imgAlt="Aquaterra"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="TD Canada Trast"
      itemHRef="https://www.td.com/ca/en/personal-banking/"
      imgSrc="./assets/clients/td.png"
      imgAlt="TD Canada Trast"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Scotiabank"
      itemHRef="https://www.scotiabank.com/ca"
      imgSrc="./assets/clients/scotiabank.png"
      imgAlt="Scotiabank"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="REA Inc."
      itemHRef="https://reainc.net/"
      imgSrc="./assets/clients/reainc.png"
      imgAlt="REA Inc."
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Lixar"
      itemHRef="https://lixar.com/"
      imgSrc="./assets/clients/lixar.png"
      imgAlt="Lixar"
    >
    </app-clients-item>
  </div>

  <h1>Indirect Clients</h1>

  <div class="clients_container">
    <app-clients-item
      class="item"
      itemHeader="Ford Motor Company"
      itemHRef="https://www.ford.ca"
      imgSrc="./assets/clients/ford.png"
      imgAlt="Ford Motor Company"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Jaguar Cars"
      itemHRef="https://www.jaguar.ca"
      imgSrc="./assets/clients/jaguar.png"
      imgAlt="Jaguar Cars"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Land Rover Canada"
      itemHRef="https://www.landrovertoronto.ca"
      imgSrc="./assets/clients/landrover.png"
      imgAlt="Land Rover Canada"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Solo mobile"
      itemHRef="https://www.solomobile.ca/en/"
      imgSrc="./assets/clients/solomobile.png"
      imgAlt="Solo mobile"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Virgin mobile"
      itemHRef="https://www.virginmobile.ca/"
      imgSrc="./assets/clients/virginmobile.png"
      imgAlt="Virgin mobile"
    >
    </app-clients-item>

    <app-clients-item
      class="item"
      itemHeader="Microsoft"
      itemHRef="https://www.microsoft.com"
      imgSrc="./assets/clients/microsoft.png"
      imgAlt="Microsoft"
    >
    </app-clients-item>
    <app-clients-item
      class="item"
      itemHeader="Greenlots"
      itemHRef="https://greenlots.com/"
      imgSrc="./assets/clients/greenlots.png"
      imgAlt="Greenlots"
    >
    </app-clients-item>
  </div>
</div>
