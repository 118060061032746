import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients-item',
  templateUrl: './clients-item.component.html',
  styleUrls: ['./clients-item.component.scss'],
})
export class ClientsItemComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() itemHeader: string = '';
  @Input() itemHRef: string = '';
  @Input() imgSrc: string = '';
  @Input() imgAlt: string = '';
}
