import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements AfterViewInit {

  constructor() { }

  @ViewChild("removeme", { read: ElementRef })
  tref!: ElementRef;

  ngAfterViewInit() {
    setTimeout(() => 
    {
      this.tref.nativeElement.remove();
    },
    2000);
  }
}

