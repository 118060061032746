<div class="details_body">
  <h2>Special thanks to the following sites for artwork provided:</h2>
  <div class="details_container">
    <mat-card class="item details-card">
      <mat-card-content>
        <div>
          Icons made by
          <a href="https://www.freepik.com" title="Freepik">Freepik</a> from
          <a href="https://www.flaticon.com/" title="Flaticon"
            >www.flaticon.com</a
          >
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="item details-card">
      <mat-card-content>
        <div>
          Image by
          <a
            href="https://pixabay.com/users/sick-street-photography-7865437/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3214943"
            >Thomas Rudesheim</a
          >
          from
          <a
            href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3214943"
            >Pixabay</a
          >
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="item details-card">
      <mat-card-content>
        <div>
          Image by
          <a
            href="https://pixabay.com/users/barbaraalane-756613/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3232784"
            >Barbara A Lane</a
          >
          from
          <a
            href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3232784"
            >Pixabay</a
          >
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="item details-card">
      <mat-card-content>
        <div>
          Image by
          <a
            href="https://pixabay.com/users/meisterbohne-8535927/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3273540"
            >MeisterBohne</a
          >
          from
          <a
            href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3273540"
            >Pixabay</a
          >
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="item details-card">
      <mat-card-content>
        <div>
          Image by
          <a
            href="https://pixabay.com/users/andrekheren-73289/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=199225"
            >andrekheren</a
          >
          from
          <a
            href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=199225"
            >Pixabay</a
          >
        </div>
      </mat-card-content>
    </mat-card>

    <ul #removeme style="visibility: hidden">
      <li class="none_bullet">
        <span>Android</span>
        <ul>
          <li>
            IDE:
            <ul>
              <li>Android Studio</li>
            </ul>
          </li>
          <li>
            Languages:
            <ul>
              <li>Java</li>
              <li>Kotlin</li>
            </ul>
          </li>
          <li>
            Architecture:
            <ul>
              <li>MVVM</li>
              <li>MVI</li>
              <li>Clean Architecture</li>
            </ul>
          </li>
          <li>Material Design</li>
          <li>
            Web integration:
            <ul>
              <li>REST</li>
              <li>Retrofit</li>
            </ul>
          </li>
          <li>
            Cloud integration:
            <ul>
              <li>Firebase / Firestore</li>
              <li>AWS</li>
            </ul>
          </li>
          <li>
            Databases:
            <ul>
              <li>SqLite</li>
              <li>Room</li>
              <li>Realm</li>
            </ul>
          </li>
          <li>
            Reactive:
            <ul>
              <li>RxJava</li>
              <li>Flow</li>
              <li>LiveData</li>
            </ul>
          </li>
          <li>
            R&D:
            <ul>
              <li>Jetpack Compose</li>
            </ul>
          </li>
        </ul>
      </li>
      <br />
      <li class="none_bullet">
        <span>iOS</span>
        <ul>
          <li>
            IDE:
            <ul>
              <li>xCode</li>
            </ul>
          </li>
          <li>
            Languages:
            <ul>
              <li>Swift</li>
            </ul>
          </li>
          <li>
            Architecture:
            <ul>
              <li>MVVM</li>
              <li>VIPER</li>
              <li>Clean Architecture</li>
            </ul>
          </li>
          <li>Material Design</li>
          <li>
            Web integration:
            <ul>
              <li>REST</li>
            </ul>
          </li>
          <li>
            Cloud integration:
            <ul>
              <li>Firebase / Firestore</li>
              <li>AWS</li>
            </ul>
          </li>
          <li>
            Databases:
            <ul>
              <li>CodeDB</li>
              <li>Realm</li>
            </ul>
          </li>
          <li>
            Reactive:
            <ul>
              <li>RxJava</li>
              <li>Combine</li>
            </ul>
          </li>
          <li>
            R&D:
            <ul>
              <li>SwiftUI</li>
            </ul>
          </li>
        </ul>
      </li>
      <br />
      <li class="none_bullet">
        <span>Cross Platform</span>
        <ul>
          <li>
            IDE:
            <ul>
              <li>Visual Studio Code</li>
            </ul>
          </li>
          <li>
            Languages:
            <ul>
              <li>Dart</li>
              <li>React Native</li>
            </ul>
          </li>
          <li>
            Frameworks
            <ul>
              <li>Flutter</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
