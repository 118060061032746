import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-net-details',
  templateUrl: './net-details.component.html',
  styleUrls: ['./net-details.component.scss']
})
export class NetDetailsComponent implements AfterViewInit {

  constructor() { }

  @ViewChild("removeme", { read: ElementRef })
  tref!: ElementRef;

  ngAfterViewInit() {
    setTimeout(() => 
    {
      this.tref.nativeElement.remove();
    },
    2000);
  }
}
