import { Component } from '@angular/core';
import { slideInAnimation } from '../animations';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  animations: slideInAnimation
})
export class ServicesComponent {

  constructor() { }
}
