<div class="details_body">
  <div class="details-card-host">
    <div class="card-header">
      <div class="fill"></div>
      <h2>Our cloud solutions are based on the following platforms:</h2>
      <div class="fill"></div>
      <!-- <button mat-fab class="cancel-image" (click)="back()"></button> -->
    </div>
    <div class="details_container">
      <mat-card class="item details-card">
        <mat-card-header>
          <div mat-card-avatar class="firebase-icon"></div>
          <mat-card-title>Firebase</mat-card-title>
          <mat-card-subtitle>Backend-as-a-Service</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          Firebase is a platform developed by Google for creating mobile and web
          applications. It provides developers with a variety of tools and
          services to help them develop quality apps, grow their user base, and
          earn profit. It is built on Google’s infrastructure.
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <div mat-card-avatar class="aws-icon"></div>
          <mat-card-title>AWS</mat-card-title>
          <mat-card-subtitle>Amazon Web Services</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          Amazon Web Services (AWS) is a bundled remote computing service that
          provides cloud computing infrastructure over the Internet with
          storage, bandwidth and customized support for application programming
          interfaces (API):
          <ul>
            <li>S3 - Simple Storage Service</li>
            <li>Lambda - Serverless Compute Service</li>
            <li>EC2 - Elastic Compute Cloud</li>
            <li>ClowdWatch - monitoring and observability service</li>
            <li>CloudFront - content delivery network</li>
            <li>IAM - Identity and Access Management</li>
            <li>SES - Simple Email Service</li>
            <li>Route 53 - cloud Domain Name System (DNS) web service</li>
          </ul>
        </mat-card-content>
      </mat-card>

      <!-- <h1>Cloud</h1>
    <ul>
        <li>Firebase</li>
        <li>AWS</li>
    </ul> -->

      <ul #removeme style="visibility: hidden">
        <li class="none_bullet">
          <span>Android</span>
          <ul>
            <li>
              IDE:
              <ul>
                <li>Android Studio</li>
              </ul>
            </li>
            <li>
              Languages:
              <ul>
                <li>Java</li>
                <li>Kotlin</li>
              </ul>
            </li>
            <li>
              Architecture:
              <ul>
                <li>MVVM</li>
                <li>MVI</li>
                <li>Clean Architecture</li>
              </ul>
            </li>
            <li>Material Design</li>
            <li>
              Web integration:
              <ul>
                <li>REST</li>
                <li>Retrofit</li>
              </ul>
            </li>
            <li>
              Cloud integration:
              <ul>
                <li>Firebase / Firestore</li>
                <li>AWS</li>
              </ul>
            </li>
            <li>
              Databases:
              <ul>
                <li>SqLite</li>
                <li>Room</li>
                <li>Realm</li>
              </ul>
            </li>
            <li>
              Reactive:
              <ul>
                <li>RxJava</li>
                <li>Flow</li>
                <li>LiveData</li>
              </ul>
            </li>
            <li>
              R&D:
              <ul>
                <li>Jetpack Compose</li>
              </ul>
            </li>
          </ul>
        </li>
        <br />
        <li class="none_bullet">
          <span>iOS</span>
          <ul>
            <li>
              IDE:
              <ul>
                <li>xCode</li>
              </ul>
            </li>
            <li>
              Languages:
              <ul>
                <li>Swift</li>
              </ul>
            </li>
            <li>
              Architecture:
              <ul>
                <li>MVVM</li>
                <li>VIPER</li>
                <li>Clean Architecture</li>
              </ul>
            </li>
            <li>Material Design</li>
            <li>
              Web integration:
              <ul>
                <li>REST</li>
              </ul>
            </li>
            <li>
              Cloud integration:
              <ul>
                <li>Firebase / Firestore</li>
                <li>AWS</li>
              </ul>
            </li>
            <li>
              Databases:
              <ul>
                <li>CodeDB</li>
                <li>Realm</li>
              </ul>
            </li>
            <li>
              Reactive:
              <ul>
                <li>RxJava</li>
                <li>Combine</li>
              </ul>
            </li>
            <li>
              R&D:
              <ul>
                <li>SwiftUI</li>
              </ul>
            </li>
          </ul>
        </li>
        <br />
        <li class="none_bullet">
          <span>Cross Platform</span>
          <ul>
            <li>
              IDE:
              <ul>
                <li>Visual Studio Code</li>
              </ul>
            </li>
            <li>
              Languages:
              <ul>
                <li>Dart</li>
                <li>React Native</li>
              </ul>
            </li>
            <li>
              Frameworks
              <ul>
                <li>Flutter</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
