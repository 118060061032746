import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material/material.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { ClientsComponent } from './clients/clients.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesItemComponent } from './services-item/services-item.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientsItemComponent } from './clients-item/clients-item.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { ArchitectureDetailsComponent } from './architecture-details/architecture-details.component';
import { MobileDetailsComponent } from './mobile-details/mobile-details.component';
import { WebDetailsComponent } from './web-details/web-details.component';
import { CloudDetailsComponent } from './cloud-details/cloud-details.component';
import { DatabasesDetailsComponent } from './databases-details/databases-details.component';
import { NetDetailsComponent } from './net-details/net-details.component';
import { CreditsComponent } from './credits/credits.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesComponent,
    ClientsComponent,
    AboutComponent,
    ContactComponent,
    ServicesItemComponent,
    ClientsItemComponent,
    ServiceDetailsComponent,
    ArchitectureDetailsComponent,
    MobileDetailsComponent,
    WebDetailsComponent,
    CloudDetailsComponent,
    DatabasesDetailsComponent,
    NetDetailsComponent,
    CreditsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
