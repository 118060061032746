import {
  animate,
  animateChild,
  group,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const slideInAnimation = [
  trigger('routeAnimations', [
    transition(
      '* => ArchitectureDetailsPage, * => MobileDetailsPage, * => WebDetailsPage, * => CloudDetailsPage, * => DatabasesDetailsPage, * => NetDetailsPage, * => CreditsPage',
      [
        style({ position: 'relative' }),
        //style({ position: 'fixed' }),

        query(
          ':leave',
          [
            style({
              position: 'absolute',
              //position: 'relative',
              //position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
            }),
          ],
          { optional: true }
        ),
        query(
          ':enter',
          [
            style({
              //position: 'absolute',
              position: 'relative',
              //position: 'fixed',
              top: 0, //window.scrollY,
              left: 0,
              width: '100%',
            }),
          ],
          { optional: true }
        ),
        query(':enter', [style({ transform: 'translateY(100%)', zIndex: 2 })], {
          optional: true,
        }),
        query(':leave', animateChild(), { optional: true }),
        group([
          query(
            ':leave',
            [
              //animate('500ms ease-in'),
              //animate('1500ms ease-out', style({transform: 'translateY(0%)'}))
              //animate('500ms ease-out',style({offset: 0}) )
              //animate('1ms', style({opacity: 0}))
            ],
            { optional: true }
          ),
          query(
            ':enter',
            [
              //animate('1ms', style({opacity: 0})),
              //animate('1ms ease-out', style({opacity: 0})),
              animate(
                '1ms 500ms ease-out',
                style({ transform: 'translateY(300vh)' })
              ),
              //animate('1ms ease-out', style({transform: 'translateY({{test}}px)'})),
              //animate('1ms ease-out', style({transform: 'translateY(100%)'})),
              animate('1000ms ease-in'),
              //animate('1ms', style({opacity: 0})),
              //animate('1ms ease-out', style({transform: 'translateY(100%)'})),
              //animate('1ms ease-out', style({opacity: 1})),
              //animate('10ms', style({opacity: 1})),
              //animate('1000ms ease-out', style({transform: 'translateY(0px)'}))
              // stagger(10000, [
              //   animate('1000ms ease-out', style({transform: 'translateY(0px)'}))
              // ])
            ],
            { optional: true }
          ),
        ]),
        query(':enter', animateChild(), { optional: true }),
      ]
    ),

    transition(
      'ArchitectureDetailsPage => *, MobileDetailsPage => *, ServiceDetailsPage => *, WebDetailsPage => *, CloudDetailsPage => *, DatabasesDetailsPage => *, NetDetailsPage => *, CreditsPage => *',
      [
        style({ position: 'relative' }),
        //style({ position: 'fixed' }),
        query(
          ':enter, :leave',
          [
            style({
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }),
          ],
          { optional: true }
        ),
        query(
          ':enter',
          [
            style({
              //transform: 'translateY(100%)',
              left: '100%',
            }),
          ],
          { optional: true }
        ),
        query(':leave', animateChild()),
        group([
          query(
            ':leave',
            [
              animate(
                '1000ms ease-out',
                style({ transform: 'translateY(100vh)' })
              ),
            ],
            { optional: true }
          ),
          query(
            ':enter',
            [animate('1000ms 1000ms ease-out', style({ left: '-0%' }))],
            { optional: true }
          ),
        ]),
        query(':enter', animateChild(), { optional: true }),
      ]
    ),

    transition('* <=> *', [
      //style({ position: 'relative' }),
      //style({ position: 'fixed' }),
      //style({ position: 'absolute' }),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            //position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true }
      ),
      query(':enter', [style({ left: '100%' })], { optional: true }),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(
          ':leave',
          [
            //stagger(5000, [
            animate('1000ms 500ms ease-out', style({ left: '-100%' })),
            //])
          ],
          { optional: true }
        ),
        query(
          ':enter',
          [
            //stagger(5000, [
            animate('1000ms 500ms ease-out', style({ left: '0%' })),
            //])
          ],
          { optional: true }
        ),
      ]),
      query(':enter', animateChild(), { optional: true }),
    ]),
  ]),
  trigger('onAppear', [
    state(
      'initial',
      style({
        opacity: 0,
        transform: 'translateY(-500px)',
      })
    ),
    state(
      'end',
      style({
        opacity: 1,
        transform: 'translateY(0)',
      })
    ),
    transition('initial => end', animate('2000ms')),
    transition('end => *', animate('1000ms')),
  ]),
];
