<div class="contact_body center">
    <div class="contact_email" [style.backgroundImage]="'url(./assets/mailbox.jpg)'">
       <!-- Email: nortohol.com -->
       <mat-card [@onAppear]= "state">
        <mat-card-title>CONTACT US</mat-card-title>
        <mat-card-content>
            <i class="material-icons">email</i> Email:
            <a href="mailto:info@nortohol.com">info@nortohol.com</a>
        </mat-card-content>
      </mat-card>
    </div>
</div>