import { ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  RouterOutlet,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { Location, ViewportScroller } from '@angular/common';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { slideInAnimation } from './animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit {
  title = 'Nortohol-inc';

  scrollPosY = 0;

  //   @HostListener("window:scroll", []) onWindowScroll() {
  //     // do some stuff here when the window is scrolled
  //     const verticalOffset = window.pageYOffset
  //           || document.documentElement.scrollTop
  //           || document.body.scrollTop || 0;
  //     console.log(verticalOffset)
  // }

  isShowBackButton = false;
  private _routerSub = Subscription.EMPTY;
  pageHeader = '';
  isHomePage = true;

  map = new Map()
    .set('/', '')
    .set('/services', ' | Services')
    .set('/clients', ' | Clients')
    .set('/about', ' | Our Team')
    .set('/contact', ' | Contact Us')
    .set('/architecture-details', ' | Architecture')
    .set('/service-details', ' | Service')
    .set('/mobile-details', ' | Mobile')
    .set('/web-details', ' | Web')
    .set('/cloud-details', ' | Cloud')
    .set('/databases-details', ' | Database')
    .set('/net-details', ' | .NET')
    .set('/credits', ' | Credits');

  constructor(private router: Router, private location: Location) {}

  scroll = (event: any): void => {
    // Here scroll is a variable holding the anonymous function
    // this allows scroll to be assigned to the event during onInit
    // and removed onDestroy
    // To see what changed:
    const number = event.srcElement.scrollTop;
    //console.log(event);
    this.scrollPosY = number;
    console.log('I am scrolling ' + number);
  };

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true);
    this._routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        console.log('NavigationStart:' + event.url);
        //this.decorate(event.url)

        this.scrollToTop(event.url);
      }
      if (event instanceof NavigationEnd) {
        console.log('NavigationEnd:' + event.url);
        this.decorate(event.url);
      }
    });

    //Browser window inner height
    let innerHeight = window.innerHeight;

    //Browser window outer height
    let outerHeight = window.outerHeight;

    //Difference between outer and inner height - some toolbars (for safari)
    let diff = Math.round(outerHeight - innerHeight);
    if (diff == 0) { 
      //Inner height equal outer height -> set bottom padding as 10% of height but no more than 50px 
      diff = Math.round(innerHeight / 10) > 50 ? 50 : Math.round(innerHeight / 10);
    }
    else {
      //Inner height NOT equal outer height -> set bottom padding to 50% of difference + 10px
      diff = Math.round(diff / 2) + 10
    }
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--diff', `${diff}px`);
    document.documentElement.style.setProperty('--innerHeight', `${window.innerHeight}px`);
    //alert("inner:" + window.innerHeight + " outer:" + window.outerHeight + " diff:" + diff);
  }

  isDetailsPage(url: String): Boolean {
    if (
      url == '/architecture-details' ||
      url == '/mobile-details' ||
      url == '/service-details' ||
      url == '/web-details' ||
      url == '/cloud-details' ||
      url == '/databases-details' ||
      url == '/net-details' ||
      url == '/credits'
    ) {
      return true;
    } else {
      return false;
    }
  }

  scrollToTop(url: String) {
    console.log('=== sctroll to top ===' + url);
    console.log('=== Y ===' + this.scrollPosY);

    document
      .getElementsByTagName('mat-sidenav-content')[0]
      .scrollTo({ left: 0, top: 0, behavior: 'smooth' });;
  }

  decorate(url: string) {
    this.checkBackButton(url);
    this.checkPageHeader(url);
    this.checkHomePage(url);
  }

  checkPageHeader(url: string) {
    console.log(url);
    this.pageHeader = this.map.get(url);
  }

  checkHomePage(url: string) {
    if (this.map.get(url) == '') 
      this.isHomePage = true;
      else this.isHomePage = false;
  }

  checkBackButton(url: string) {
    if (this.isDetailsPage(url))
      this.isShowBackButton = true;
    else this.isShowBackButton = false;
  }

  setBackButton(isShow: boolean): void {
    console.log(isShow);

    this.isShowBackButton = isShow;
  }

  back() {
    this.location.back();
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
    this._routerSub.unsubscribe();
  }
}
