<mat-card class="card">
  <mat-card-header>
    <mat-card-title>{{ itemHeader }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="flex-container">
      <div class="flex-item">
        <span class="background-circle">
          <img src="{{ imgSrc }}" [ngStyle]="getImgCss()" alt="{{ imgAlt }}" />
        </span>
      </div>
    </div>

    <div [innerHtml]="itemText" class="item_text"></div>
  </mat-card-content>
  <mat-card-actions fxFlexAlign="center">
    <a href="#" routerLink="{{ urlDetails }}">
      <button mat-stroked-button>Learn more</button>
    </a>
  </mat-card-actions>
</mat-card>
