<div class="details_body">
  <div class="details-card-host">
    <div class="card-header">
      <div class="fill"></div>
      <h2>In our development we employ the following architectures:</h2>
      <div class="fill"></div>
      <!-- <button mat-fab  class="cancel-image" (click)="back()"></button> -->
    </div>
    <div class="details_container">
      <mat-card class="item details-card">
        <mat-card-header>
          <mat-card-title>Clean Architecture</mat-card-title>
          <!-- <mat-card-subtitle>Modern approach to architect mid/large systems.</mat-card-subtitle> -->
          <mat-card-subtitle>“Plug-in architecture” approach</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          The approach to system architecture focusing on decoupling business
          entities / rules from implementation. Created by Robert C. Martin. Clean
          Architecture has the following benifits:
          <ul>
            <li>Independent of Database and Frameworks.</li>
            <li>Independence of UI.</li>
            <li>Testable.</li>
          </ul>
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <mat-card-title>MVC</mat-card-title>
          <mat-card-subtitle>Model-View-Controller</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          MVC architecture applied to web development. MVC is an application
          design model comprised of three interconnected parts. They include the
          model (data), the view (user interface), and the controller (processes
          that handle input).
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <mat-card-title>MVVM</mat-card-title>
          <mat-card-subtitle>Model-View-ViewModel</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          MVVM architecture for mobile devices. MVVM is a structural design
          pattern that separates objects into three distinct groups:
          <ul>
            <li>Models hold application data.</li>
            <li>Views display visual elements and controls on the screen.</li>
            <li>
              View models transform model information into values that can be
              displayed on a view.
            </li>
          </ul>
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <mat-card-title>MVI</mat-card-title>
          <mat-card-subtitle>Model-View-Intent</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          MVI - modern architecture for mobile devices. MVI architectural pattern
          has been introduced recently in Android. It works based on the principle
          of unidirectional and cylindrical flow inspired by the Cycle.js
          framework.
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <mat-card-title>VIPER</mat-card-title>
          <mat-card-subtitle>View, Interactor, Presenter, Entity and Router</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          VIPERR - modern architecture for complex projects on iOS devices. The
          VIPER architecture is based on the single responsibility principle which
          aims at solving the common Massive View Controller problem in iOS apps.
          When implemented to its full extent, it achieves separation of concerns
          between modules in your code, which yields testability.
        </mat-card-content>
      </mat-card>
    </div>
    <h2>...and principles:</h2>
    <div class="details_container">
      <mat-card class="item details-card">
        <mat-card-header>
          <mat-card-title>SOLID Principles</mat-card-title>
          <mat-card-subtitle>First five object-oriented design (OOD) principles by Robert C.
            Martin</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <p><span>S</span> - Single Responsibility Principle</p>
          <p><span>O</span> - Open-Closed Principle</p>
          <p><span>L</span> - Single Responsibility Principle</p>
          <p><span>I</span> - Interface Segregation Principle</p>
          <p><span>D</span> - Dependency Inversion Principle</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>