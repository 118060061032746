<div class="services_body">
  <div class="padding_top"></div>
  <div class="services_header">We deliver the following services</div>
  <div class="services_container">
    <app-services-item
      class="item item_body_first"
      itemHeader="Archtecture"
      itemText="<ul>
            <li>System Architecture</li>
            <li>Design</li>
        </ul>"
      imgSrc="../assets/icons/ideas.svg"
      imgCss='{"position": "relative", "bottom.px": 5, "right.px": 3}'
      imgAlt="Architecture"
      urlDetails="/architecture-details"
    >
    </app-services-item>

    <app-services-item
      class="item item_body"
      itemHeader="MOBILE"
      itemText="<ul>
            <li>Android Development</li>
            <li>iOS Development</li>
            <li>Cross platform Development</li>
        </ul>"
      imgSrc="../assets/icons/smartphone.svg"
      imgCss='{"position": "relative", "bottom.px": 3, "right.px": 2}'
      imgAlt="Mobile"
      urlDetails="/mobile-details"
    >
    </app-services-item>

    <app-services-item
      class="item item_body"
      itemHeader="WEB"
      itemText="<ul>
            <li>Front end design/development</li>
            <li>Web/mobile integration</li>
        </ul>"
      imgSrc="../assets/icons/domain.svg"
      imgCss='{"position": "relative", "bottom.px": 2, "right.px": 2}'
      imgAlt="Web"
      urlDetails="/web-details"
    >
    </app-services-item>

    <app-services-item
      class="item item_body"
      itemHeader="Cloud"
      itemText="<ul>
            <li>AWS development</li>
            <li>Google Cloud Platform</li>
            <li>Firebase</li>
        </ul>"
      imgSrc="../assets/icons/cloud.svg"
      imgCss='{"position": "relative", "bottom.px": 5, "right.px": 1}'
      imgAlt="Web"
      urlDetails="/cloud-details"
    >
    </app-services-item>

    <app-services-item
      class="item item_body"
      itemHeader="Databases"
      itemText="<ul>
            <li>SQL Database Development</li>
            <li>NoSQL Database Development</li>
        </ul>"
      imgSrc="../assets/icons/data-storage.svg"
      imgCss='{"position": "relative", "bottom.px": 0, "right.px": 0}'
      imgAlt="DB"
      urlDetails="/databases-details"
    >
    </app-services-item>

    <!-- <app-services-item
      class="item item_body_last"
      itemHeader=".NET"
      itemText="<ul>
            <li>.NET Development</li>
            <li>ASP .NET Core Development</li>
        </ul>"
      imgSrc="../assets/icons/asp.svg"
      imgCss='{"position": "relative", "bottom.px": 3, "right.px": 2}'
      imgAlt="NET"
      urlDetails="/net-details"
    >
    </app-services-item> -->
  </div>
</div>
