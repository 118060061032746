<div class="details_body">
  <div class="details-card-host">
    <div class="card-header">
      <div class="fill"></div>
      <h2>We utilize the following databases in our solutions:</h2>
      <div class="fill"></div>
      <!-- <button mat-fab class="cancel-image" (click)="back()"></button> -->
    </div>
    <div class="details_container">
      <mat-card class="item details-card">
        <mat-card-header>
          <div mat-card-avatar class="mssql-icon"></div>
          <mat-card-title>MS SQL</mat-card-title>
          <mat-card-subtitle>Microsoft SQL Server</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          Microsoft SQL Server is a RDBMS (relational database management
          system) created by Microsoft. It is used as a central location to save
          and obtain data needed for applications. It uses SQL (structured query
          language) for queries that store or retrieve data.
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <div mat-card-avatar class="mongo-icon"></div>
          <mat-card-title>Mongo DB</mat-card-title>
          <mat-card-subtitle>NoSQL database</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          MongoDB is an object-oriented, simple, dynamic, and scalable NoSQL
          database. It is based on the NoSQL document store model. The data
          objects are stored as separate documents inside a collection — instead
          of storing the data into the columns and rows of a traditional
          relational database.
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <div mat-card-avatar class="realm-icon"></div>
          <mat-card-title>Realm SDK</mat-card-title>
          <mat-card-subtitle>NoSql for Android, iOS</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          The MongoDB Realm SDKs are client libraries that allow you to connect
          to local and synced Realm databases, authenticate users, and interact
          with serverless MongoDB Realm applications. The SDKs include Realm
          Database, an alternative to SQLite and Core Data, so you can get
          started with just the SDK -- no additional dependencies required.
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <div mat-card-avatar class="sqlite-icon"></div>
          <mat-card-title>Sqlite</mat-card-title>
          <mat-card-subtitle>Embedded Relational Database</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          SQLite is a software library that provides a relational database
          management system. The lite in SQLite means lightweight in terms of
          setup, database administration, and required resources. SQLite has the
          following noticeable features: self-contained, serverless,
          zero-configuration, transactional.
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <div mat-card-avatar class="room-icon"></div>
          <mat-card-title>Room</mat-card-title>
          <mat-card-subtitle>Database Object Mapping library</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          The Room persistence library provides an abstraction layer over SQLite
          to allow for more robust database access while harnessing the full
          power of SQLite.
        </mat-card-content>
      </mat-card>

      <mat-card class="item details-card">
        <mat-card-header>
          <div mat-card-avatar class="coredata-icon"></div>
          <mat-card-title>Core Data</mat-card-title>
          <mat-card-subtitle
            >Object graph and persistence framework provided by
            Apple</mat-card-subtitle
          >
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          Core Data is a framework used to manage the model layer objects in the
          application. It provides generalized and automated solutions to common
          tasks associated with object life cycle and object graph management,
          including persistence.
        </mat-card-content>
      </mat-card>

      <ul #removeme style="visibility: hidden">
        <li class="none_bullet">
          <span>Android</span>
          <ul>
            <li>
              IDE:
              <ul>
                <li>Android Studio</li>
              </ul>
            </li>
            <li>
              Languages:
              <ul>
                <li>Java</li>
                <li>Kotlin</li>
              </ul>
            </li>
            <li>
              Architecture:
              <ul>
                <li>MVVM</li>
                <li>MVI</li>
                <li>Clean Architecture</li>
              </ul>
            </li>
            <li>Material Design</li>
            <li>
              Web integration:
              <ul>
                <li>REST</li>
                <li>Retrofit</li>
              </ul>
            </li>
            <li>
              Cloud integration:
              <ul>
                <li>Firebase / Firestore</li>
                <li>AWS</li>
              </ul>
            </li>
            <li>
              Databases:
              <ul>
                <li>SqLite</li>
                <li>Room</li>
                <li>Realm</li>
              </ul>
            </li>
            <li>
              Reactive:
              <ul>
                <li>RxJava</li>
                <li>Flow</li>
                <li>LiveData</li>
              </ul>
            </li>
            <li>
              R&D:
              <ul>
                <li>Jetpack Compose</li>
              </ul>
            </li>
          </ul>
        </li>
        <br />
        <li class="none_bullet">
          <span>iOS</span>
          <ul>
            <li>
              IDE:
              <ul>
                <li>xCode</li>
              </ul>
            </li>
            <li>
              Languages:
              <ul>
                <li>Swift</li>
              </ul>
            </li>
            <li>
              Architecture:
              <ul>
                <li>MVVM</li>
                <li>VIPER</li>
                <li>Clean Architecture</li>
              </ul>
            </li>
            <li>Material Design</li>
            <li>
              Web integration:
              <ul>
                <li>REST</li>
              </ul>
            </li>
            <li>
              Cloud integration:
              <ul>
                <li>Firebase / Firestore</li>
                <li>AWS</li>
              </ul>
            </li>
            <li>
              Databases:
              <ul>
                <li>CodeDB</li>
                <li>Realm</li>
              </ul>
            </li>
            <li>
              Reactive:
              <ul>
                <li>RxJava</li>
                <li>Combine</li>
              </ul>
            </li>
            <li>
              R&D:
              <ul>
                <li>SwiftUI</li>
              </ul>
            </li>
          </ul>
        </li>
        <br />
        <li class="none_bullet">
          <span>Cross Platform</span>
          <ul>
            <li>
              IDE:
              <ul>
                <li>Visual Studio Code</li>
              </ul>
            </li>
            <li>
              Languages:
              <ul>
                <li>Dart</li>
                <li>React Native</li>
              </ul>
            </li>
            <li>
              Frameworks
              <ul>
                <li>Flutter</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
