import { AfterViewInit, Component, OnInit } from '@angular/core';
import { slideInAnimation } from '../animations';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: slideInAnimation
})
export class ContactComponent implements OnInit, AfterViewInit {
  load_completed = false;
  state:string = 'initial';

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout( () => {
      console.log("ngAfterViewInit");
      this.state = 'end';
      this.load_completed = true;
    }, 200);
}

  ngOnDestroy() {
    setTimeout( () => {
      console.log("ngOnDestroy");
      this.state = 'initial';
    }, 200);
  }
}
