<div class="home_body">

  <ngb-carousel
  [interval]="5000">
  <ng-template ngbSlide>
    <div class="center">
      <img src="../assets/carousel/4.jpg" alt="Angular Carousel 1">
    </div>
    <div class="carousel-caption">
      <h1>Nortohol Inc</h1>
      <p>Nortohol Inc. is a privately owned and operated Hi-Tech company.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="center">
      <img src="../assets/carousel/3.jpg" alt="Angular Carousel 2">
    </div>
    <div class="carousel-caption">
      <h1>Our Mission</h1>
      <p>Our mission is to deliver top quality technology solutions in software development and consulting services.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="center">
      <img src="../assets/carousel/1.jpg" alt="Angular Carousel 2">
    </div>
    <div class="carousel-caption">
      <h1>Our Service</h1>
      <p>Our service is Web and Mobile development. Since 2007 we deliver our expertise in Greater Toronto Area.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <!-- picsum-img-wrapper -->
    <div class="center">
      <img src="../assets/carousel/2.jpg" alt="Angular Carousel 2">
    </div>
    <div class="carousel-caption">
      <h1>Our Business Model</h1>
      <p>Our unique business management model allows us to deliver exceptional cost effective service.</p>
    </div>
  </ng-template>
</ngb-carousel>
</div>