<div class="details_body">
  <h2>We utilize the following databases in our solutions:</h2>
  <div class="details_container">
    <mat-card class="item details-card">
      <mat-card-header>
        <mat-card-title>ASP.NET Core 5.0</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <p>Add description</p>
      </mat-card-content>
    </mat-card>

    <mat-card class="item details-card">
      <mat-card-header>
        <mat-card-title>ASP.NET MVC</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <p>Add description</p>
      </mat-card-content>
    </mat-card>

    <!-- <h1>.NET</h1> -->
    <!-- <ul>
        <li>ASP.NET Core 5.0</li>
        <li>ASP.NET MVC</li>
    </ul> -->
    <ul #removeme style="visibility: hidden">
      <li class="none_bullet">
        <span>Android</span>
        <ul>
          <li>
            IDE:
            <ul>
              <li>Android Studio</li>
            </ul>
          </li>
          <li>
            Languages:
            <ul>
              <li>Java</li>
              <li>Kotlin</li>
            </ul>
          </li>
          <li>
            Architecture:
            <ul>
              <li>MVVM</li>
              <li>MVI</li>
              <li>Clean Architecture</li>
            </ul>
          </li>
          <li>Material Design</li>
          <li>
            Web integration:
            <ul>
              <li>REST</li>
              <li>Retrofit</li>
            </ul>
          </li>
          <li>
            Cloud integration:
            <ul>
              <li>Firebase / Firestore</li>
              <li>AWS</li>
            </ul>
          </li>
          <li>
            Databases:
            <ul>
              <li>SqLite</li>
              <li>Room</li>
              <li>Realm</li>
            </ul>
          </li>
          <li>
            Reactive:
            <ul>
              <li>RxJava</li>
              <li>Flow</li>
              <li>LiveData</li>
            </ul>
          </li>
          <li>
            R&D:
            <ul>
              <li>Jetpack Compose</li>
            </ul>
          </li>
        </ul>
      </li>
      <br />
      <li class="none_bullet">
        <span>iOS</span>
        <ul>
          <li>
            IDE:
            <ul>
              <li>xCode</li>
            </ul>
          </li>
          <li>
            Languages:
            <ul>
              <li>Swift</li>
            </ul>
          </li>
          <li>
            Architecture:
            <ul>
              <li>MVVM</li>
              <li>VIPER</li>
              <li>Clean Architecture</li>
            </ul>
          </li>
          <li>Material Design</li>
          <li>
            Web integration:
            <ul>
              <li>REST</li>
            </ul>
          </li>
          <li>
            Cloud integration:
            <ul>
              <li>Firebase / Firestore</li>
              <li>AWS</li>
            </ul>
          </li>
          <li>
            Databases:
            <ul>
              <li>CodeDB</li>
              <li>Realm</li>
            </ul>
          </li>
          <li>
            Reactive:
            <ul>
              <li>RxJava</li>
              <li>Combine</li>
            </ul>
          </li>
          <li>
            R&D:
            <ul>
              <li>SwiftUI</li>
            </ul>
          </li>
        </ul>
      </li>
      <br />
      <li class="none_bullet">
        <span>Cross Platform</span>
        <ul>
          <li>
            IDE:
            <ul>
              <li>Visual Studio Code</li>
            </ul>
          </li>
          <li>
            Languages:
            <ul>
              <li>Dart</li>
              <li>React Native</li>
            </ul>
          </li>
          <li>
            Frameworks
            <ul>
              <li>Flutter</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
