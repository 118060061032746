import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services-item',
  templateUrl: './services-item.component.html',
  styleUrls: ['./services-item.component.scss']
})
export class ServicesItemComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    console.log("Services:"+ this.itemHeader);
  }

  @Input() itemHeader: string = "";
  @Input() itemText: string = "";
  @Input() imgSrc: string = "";
  @Input() imgCss: string = "";
  @Input() imgAlt: string = "";
  @Input() urlDetails: string = "";

  getImgCss():Object {
    return JSON.parse(this.imgCss)
  }
}
