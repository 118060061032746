import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { ClientsComponent } from './clients/clients.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { ArchitectureDetailsComponent } from './architecture-details/architecture-details.component';
import { MobileDetailsComponent } from './mobile-details/mobile-details.component';
import { WebDetailsComponent } from './web-details/web-details.component';
import { CloudDetailsComponent } from './cloud-details/cloud-details.component';
import { DatabasesDetailsComponent } from './databases-details/databases-details.component';
import { NetDetailsComponent } from './net-details/net-details.component';
import { CreditsComponent } from './credits/credits.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: {animation: 'HomePage'}},
  { path: 'services', component: ServicesComponent, data: {animation: 'ServicesPage'}},
  { path: 'clients', component: ClientsComponent, data: {animation: 'ClientsPage'}},
  { path: 'about', component: AboutComponent, data: {animation: 'AboutPage'}},
  { path: 'contact', component: ContactComponent, data: {animation: 'ContactPage'}},
  { path: 'service-details', component: ServiceDetailsComponent, data: {animation: 'ServiceDetailsPage'}},
  { path: 'architecture-details', component: ArchitectureDetailsComponent, data: {animation: 'ArchitectureDetailsPage'}},
  { path: 'mobile-details', component: MobileDetailsComponent, data: {animation: 'MobileDetailsPage'}},
  { path: 'web-details', component: WebDetailsComponent, data: {animation: 'WebDetailsPage'}},
  { path: 'cloud-details', component: CloudDetailsComponent, data: {animation: 'CloudDetailsPage'}},
  { path: 'databases-details', component: DatabasesDetailsComponent, data: {animation: 'DatabasesDetailsPage'}},
  { path: 'net-details', component: NetDetailsComponent, data: {animation: 'NetDetailsPage'}},
  { path: 'credits', component: CreditsComponent, data: {animation: 'CreditsPage'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {
    scrollPositionRestoration: 'top'
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }

