import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Location, ViewportScroller } from '@angular/common';
import { slideInAnimation } from '../animations';

@Component({
  selector: 'app-cloud-details',
  templateUrl: './cloud-details.component.html',
  styleUrls: ['./cloud-details.component.scss'],
  animations: slideInAnimation
})
export class CloudDetailsComponent implements AfterViewInit {

  constructor(private location: Location) { }

  @ViewChild("removeme", { read: ElementRef })
  tref!: ElementRef;

  ngAfterViewInit() {
    setTimeout(() => 
    {
      this.tref.nativeElement.remove();
    },
    2000);
  }

  back() {
    this.location.back();
  }
}
