<div class="about_body">
  <div class="about_container">
    <div class="item">
      <mat-card class="top_card">
        <img class="center_img" src="../assets/images/dk.png" />
        <mat-card-title>Dmitri Kondramachine</mat-card-title>
        <mat-card-subtitle>Founder, M.Sc. in Computer Science</mat-card-subtitle>
        <mat-card-content>
          <p>
            Mr. Kondramachine is the founder and president of Nortohol Inc. 
            He graduated from Peter the Great Saint Petersburg Polytechnic University, 
            Saint Petersburg, Russia, with M.Sc. in Computer Science degree in 1995. 
            March 1, 2007 he founded Nortohol Inc., Ontario registered corporation, 
            to provide IT consulting services for large and mid-sized companies located 
            in Greater Toronto Area.
          </p>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="item">
      <mat-card class="top_card">
        <img class="center_img" src="../assets/images/em.png" />
        <mat-card-title>Elena Melnikova</mat-card-title>
        <mat-card-subtitle>Web/Mobile Developer, M.Sc.</mat-card-subtitle>
        <mat-card-content>
          <p>
            Mrs. Melnikova graduated from Tula State University, Tula, Russia,
            with M.Sc. degree in 2006. She joined Nortohol Inc. in 2016 and 
            worked on multiple web/mobile projects. She also graduated from Centennial College, 
            Ontario, with Mobile Application Development with High Honors 
            certificate in 2019.
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
